import { doc, getDoc } from "firebase/firestore";
import { db, functions } from "../../../firebaseConfig";
import { httpsCallable } from "firebase/functions";

// Data sent to the function
interface AddToWalletData {
  userID: string;
  storeID: string;
  cardDocId: string;
}
// Define the shape of the expected response
interface AddToWalletResponse {
  code: "cardAdded" | "cardExists";
}

// Call the function and type the response
const addToWalletFn = httpsCallable<AddToWalletData, AddToWalletResponse>(
  functions,
  "addToWallet"
);

export async function handleAddCardToWallet(
  userID: string,
  storeID: string,
  cardDocId: string
) {
  try {
    // Check if the card already exists in Firestore
    const userCardRef = doc(
      db,
      "users",
      userID,
      "customerLoyaltyCards",
      cardDocId
    );
    const userCardSnapshot = await getDoc(userCardRef);

    if (userCardSnapshot.exists()) {
      return { code: "cardExists" };
    }

    // Card doesn't exist; call the Cloud Function to add it
    const response = await addToWalletFn({ userID, storeID, cardDocId });

    if (response.data.code === "cardAdded") {
    } else if (response.data.code === "cardExists") {
    }

    return response.data; // Optionally return response data
  } catch (error) {
    console.error("Error handling Add Card to Wallet:", error);
    throw error; // Re-throw the error for further handling
  }
}
