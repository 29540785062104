import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";

interface CreateUserResponse {
  success: boolean;
  message: string;
  data?: {
    email: string;
    qrID: string;
    createdAt: any;
  };
}

const getUserData = async (userID: string, user: any) => {
  try {
    const userRef = doc(db, "users", userID);
    const docSnap = await getDoc(userRef);
    const functions = getFunctions();

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      if (user) {
        // Check if user is authenticated and call the cloud function
        const createUserDoc = httpsCallable(functions, "createUserDoc");
        const response = await createUserDoc({
          userID: userID,
          email: auth.currentUser?.email,
        });

        const responseData = response.data as CreateUserResponse;

        if (responseData.success) {
          return responseData.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
};

export default getUserData;

export interface UserProfile {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  birthday?: string;
  referralCode?: string;
}

export const updatePhone = async (userID: string, phone: string) => {
  const docRef = doc(db, "users", userID);
  return updateDoc(docRef, { phone });
};
