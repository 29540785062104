import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  SxProps,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import { User } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserProfile } from "../../components/auth/firestore/getUserDetails";

interface BirthdayFieldProps {
  user: User | null;
  userData: UserProfile | null;
  setUserData: React.Dispatch<React.SetStateAction<UserProfile>>;
  textFieldSx: SxProps<Theme>;
}

const BirthdayField: React.FC<BirthdayFieldProps> = ({
  user,
  userData,
  setUserData,
  textFieldSx,
}) => {
  const [editBirthday, setEditBirthday] = useState<Dayjs | null>(null);
  const [isEditingBirthday, setIsEditingBirthday] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("info");

  // For calling CF
  const functions = getFunctions();
  const updateBirthdayCF = httpsCallable<
    { userID: string; isoDate: string },
    { success: boolean; message: string }
  >(functions, "updateBirthdayCF");

  useEffect(() => {
    if (userData?.birthday) {
      setEditBirthday(dayjs(userData.birthday));
    }
  }, [userData]);

  const handleSetBirthday = (newDate: Dayjs | null) => {
    setEditBirthday(newDate);
  };

  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "info" | "warning"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleButtonClick = () => {
    if (!user?.uid) return;

    // If the user already has a birthday, do nothing (or show info).
    if (userData?.birthday) {
      showSnackbar("Birthday already set. You cannot change it.", "info");
      return;
    }

    // If not editing, toggle to editing mode
    if (!isEditingBirthday) {
      setIsEditingBirthday(true);
      return;
    }

    // If editing, we check if the user actually selected a date
    if (!editBirthday) {
      showSnackbar("Please select a date before updating.", "warning");
      return;
    }

    // Open the confirm dialog
    setIsConfirmDialogOpen(true);
  };

  const handleConfirm = async () => {
    setIsConfirmDialogOpen(false);

    if (!user?.uid || !editBirthday) return;

    try {
      // Call the Cloud Function
      const response = await updateBirthdayCF({
        userID: user.uid,
        isoDate: editBirthday.toISOString(),
      });

      if (response.data.success) {
        // Update local state
        setUserData((prev) => ({
          ...prev,
          birthday: editBirthday.toISOString(),
        }));
        showSnackbar("Birthday updated successfully!", "success");
      } else {
        // The CF reported a failure (e.g., bday was already set)
        showSnackbar(
          `Failed to update birthday: ${response.data.message}`,
          "error"
        );
      }
    } catch (err) {
      showSnackbar(
        `Failed to update birthday. ${(err as Error).message}`,
        "error"
      );
    } finally {
      setIsEditingBirthday(false);
    }
  };

  const handleCancel = () => {
    setIsConfirmDialogOpen(false);
  };

  // If the user already has a birthday, just show it (read-only)
  if (userData?.birthday) {
    return (
      <Tooltip title="DOB cannot be changed" arrow>
        <Box>
          <DatePicker
            label="Birthday"
            value={dayjs(userData.birthday)}
            disabled
            slotProps={{
              textField: {
                fullWidth: true,
                sx: textFieldSx,
              },
            }}
          />
        </Box>
      </Tooltip>
    );
  }

  // Else show the set/update logic
  return (
    <>
      <Box>
        <Tooltip title="DOB can only be set once." arrow>
          <span>
            <DatePicker
              label="Birthday"
              disabled={!isEditingBirthday}
              value={editBirthday}
              onChange={handleSetBirthday}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: textFieldSx,
                },
              }}
            />
          </span>
        </Tooltip>

        <Button
          variant="contained"
          sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
          onClick={handleButtonClick}
        >
          {isEditingBirthday ? "Confirm Birthday" : "Set Birthday"}
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={isConfirmDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirm Birthday</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Birthday cannot be changed once set. Confirm date?
            <br />
            {`${editBirthday?.format("MMMM D, YYYY")}.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BirthdayField;
