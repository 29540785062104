import React, { useContext, useState, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Theme,
  Avatar,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { LogoutDialog } from "../reusables/dialogs";
import { authClient } from "../lib/auth/client";
import stompBack2 from "../../assets/stomp-back2.svg";
import { UserContext } from "../../contexts/user-context";
import StompLogo from "../../stompCardsLogo.svg";
import { Link, useNavigate } from "react-router-dom";

const ThinHeader: React.FC = () => {
  const context = useContext(UserContext);
  const user = context?.user;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLogoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  useEffect(() => {
    document.body.style.backgroundImage = `url(${stompBack2})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "repeat-y";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const userID = user?.uid || "";
  const displayName = user?.displayName || "User";
  const photoURL = user?.photoURL || "";

  // Toggle the menu when avatar is clicked
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => setLogoutDialogOpen(true);

  const confirmLogout = () => {
    handleMenuClose();
    localStorage.clear();
    authClient.signOut();
    window.location.reload();
  };

  // Navigation handlers:
  const handleNavigateToWallet = () => {
    handleMenuClose();
    navigate("/wallet");
  };

  const handleNavigateToProfile = () => {
    handleMenuClose();
    navigate("/profile");
  };

  // Generate avatar content:
  const getAvatarContent = () => {
    if (photoURL) {
      return (
        <Avatar
          sx={{
            width: 38,
            height: 38,
            transition: "transform 0.2s",
            "&:hover": { transform: "scale(1.05)" },
          }}
          alt={displayName}
          src={photoURL}
        />
      );
    }
    // Fallback to first letter if no photoURL
    const firstLetter = displayName.charAt(0).toUpperCase();
    return (
      <Avatar
        sx={{
          backgroundColor: "#2196f3",
          width: 38,
          height: 38,
          transition: "transform 0.2s",
          "&:hover": { transform: "scale(1.05)" },
        }}
      >
        {firstLetter}
      </Avatar>
    );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Stomp Cards: The Digital Wallet to Stomp Loyalty</title>
        <meta
          name="description"
          content="Stomp Cards is your app-less wallet to access all your Stomp Loyalty stamp cards. If you prefer a traditional app layout, please install our website under settings with the add to Home Screen Button!"
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "10px 10px",
          backgroundColor: "rgba(33, 33, 33, 1)",
          zIndex: 1301,
          boxShadow: "0 0 10px rgba(100, 100, 100, 0.2)",
          height: "35px",
        }}
      >
        <Box sx={{ mb: "-5.5px" }}>
          <Link to="/wallet" style={{ textDecoration: "none" }}>
            {isLargeScreen ? (
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              >
                Stomp Cards
              </Typography>
            ) : (
              <Box
                component="img"
                src={StompLogo}
                alt="Stomp Cards: Wallet"
                sx={{ height: 40, borderRadius: "50%" }}
              />
            )}
          </Link>
        </Box>

        {/* AVATAR ICON + MENU */}
        {userID && (
          <>
            <IconButton onClick={handleAvatarClick} sx={{ color: "white" }}>
              {getAvatarContent()}
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ mt: "1px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleNavigateToWallet}
                >
                  Wallet
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleNavigateToProfile}
                >
                  Profile
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>

            {/* LOGOUT DIALOG */}
            <LogoutDialog
              open={isLogoutDialogOpen}
              onCancel={() => setLogoutDialogOpen(false)}
              onConfirm={confirmLogout}
            />
          </>
        )}
      </Box>
    </HelmetProvider>
  );
};

export default ThinHeader;
