import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  SxProps,
  Theme,
} from "@mui/material";
import { User } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserProfile } from "../../components/auth/firestore/getUserDetails";

interface NameFieldProps {
  user: User | null;
  userData: UserProfile | null;
  setUserData: React.Dispatch<React.SetStateAction<UserProfile>>;
  textFieldSx: SxProps<Theme>;
}

const NameField: React.FC<NameFieldProps> = ({
  user,
  userData,
  setUserData,
  textFieldSx,
}) => {
  const [editName, setEditName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);

  // --- Snackbar States ---
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("info");

  // Prepare callable function
  const functions = getFunctions();
  const updateNameCF = httpsCallable<
    { userID: string; firstName: string; lastName: string },
    { success: boolean; message: string }
  >(functions, "updateNameCF");

  useEffect(() => {
    if (userData) {
      const combinedName = `${userData.firstName ?? ""} ${
        userData.lastName ?? ""
      }`.trim();
      setEditName(combinedName);
    }
  }, [userData]);

  // Helper to display snackbar
  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "info" | "warning"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const handleButtonClick = async () => {
    if (!user?.uid) return;

    if (!isEditingName) {
      // Switch to editing mode
      setIsEditingName(true);
    } else {
      // Attempt to update
      const [firstName, ...rest] = editName.trim().split(" ");
      const lastName = rest.join(" ");

      // Optional local validation before calling the server
      if (firstName.length > 100) {
        showSnackbar("First name cannot exceed 100 characters.", "error");
        return;
      }
      if (lastName.length > 100) {
        showSnackbar("Last name cannot exceed 100 characters.", "error");
        return;
      }

      try {
        // Call the Cloud Function
        const response = await updateNameCF({
          userID: user.uid,
          firstName,
          lastName,
        });

        if (response.data.success) {
          // Update local state
          setUserData((prev) => ({
            ...prev,
            firstName,
            lastName,
          }));
          showSnackbar("Name updated successfully!", "success");
        } else {
          showSnackbar(
            `Failed to update name: ${response.data.message}`,
            "error"
          );
        }
      } catch (error) {
        showSnackbar(
          `Error calling Cloud Function: ${(error as Error).message}`,
          "error"
        );
      } finally {
        setIsEditingName(false);
      }
    }
  };

  return (
    <Box>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        value={editName}
        onChange={(e) => setEditName(e.target.value)}
        disabled={!isEditingName}
        sx={textFieldSx}
      />
      <Button
        variant="contained"
        sx={{ mt: 2, fontWeight: "bold", width: "100%" }}
        onClick={handleButtonClick}
      >
        {isEditingName ? "Update Name" : "Edit Name"}
      </Button>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NameField;
